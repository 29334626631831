import amplitude from 'amplitude-js';

export const initAmplitudeOnClick = () => {
    const elements = document.querySelectorAll('[data-amplitude-element]');

    elements.forEach((element) => {
        element.addEventListener('click', function () {
            const fields = {
                email: document.querySelector('[data-email-hash]').value,
                message: document.querySelector('[data-message-hash]').value,
            };
            const event = this.dataset.amplitudeElement;
            amplitude.getInstance().logEvent(event, fields);
        });
    });
};
