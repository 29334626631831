import { initForms } from './components/initForms';
import { initJivosite } from './components/initJivosite';
import { initHashEmail } from './components/initHashEmail';
import { initAmplitude } from './components/initAmplitude';
import { initHashMessage } from './components/initHashMessage';
import { initAutoresizeTextarea } from './components/initAutoresizeTextarea';

window.addEventListener('load', handleOnLoadPage, false);

function handleOnLoadPage() {
    initAutoresizeTextarea();
    initForms();
    initJivosite();
    initHashEmail();
    initHashMessage();
    initAmplitude();
}
