import amplitude from 'amplitude-js';

export const initAmplitudeOnLoad = () => {
    const amplitudePage = document.querySelector('[data-amplitude-page]');

    if (amplitudePage) {
        const event = amplitudePage.dataset.amplitudePage;
        amplitude.getInstance().logEvent(event);
    }
};
